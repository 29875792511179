<template>
  <div class="user">
    <div class="left">
      <ul>
        <li :class="['option', isActive('info') ? 'active' : '']" @click="routeTo('/user/info')">
          Information
        </li>
        <hr />
        <li :class="['option', isActive('activity_history') ? 'active' : '']"
          @click="routeTo('/user/activity_history')">
          Activity Prediction
        </li>
        <li :class="['option', isActive('virtual_history') ? 'active' : '']" @click="routeTo('/user/virtual_history')">
          Virtual Screening
        </li>
        <li :class="['option', isActive('molecular_history') ? 'active' : '']"
          @click="routeTo('/user/molecular_history')">
          Molecular Genaration
        </li>
      </ul>
    </div>
    <div class="right">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  name: "user",
  data() {
    return {};
  },
  methods: {
    routeTo(path) {
      this.$router.push({ path });
    },
    isActive(name) {
      return this.$route.path.endsWith(name);
    },
  },
  computed: {},
};
</script>

<style scoped>
.user {
  display: flex;
  padding-top: 60px;
  color: white;
  /* background-image: linear-gradient(var(--color1-dark), var(--color1-light)); */
}

.left {
  display: flex;
  height: 100vh;
  position: sticky;
  top: 0px;
  left: 0px;
  bottom: 0px;
}

.left ul {
  list-style-type: none;
  height: fit-content;
  margin: 80px 50px;
}

.option {
  font-size: 16pt;
  text-align: center;
  margin: 30px 0px;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
  transition: 300ms ease;
}


.option.active {
  border: 1px solid white;
}

.right {
  flex: 1;
  display: flex;
  height: fit-content;
  /* background: var(--color2-light); */
  min-height: 100vh;
  margin: 50px 0px;
  margin-right: 30px;
}
</style>
